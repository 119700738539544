.tik-tok-toe-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
}

.tik-tok-toe {
  display: grid;
  grid-template-columns: auto auto auto;
  width: 280px;
  gap: 5px;
}

.field {
  border: none;
  background-color: #aaa;
  width: 100%;
  height: 90px;
  padding: 0;
  margin: 0;
  position: relative;
}

.field span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 50px;
  font-weight: 700;
}
.anouncements-container {
  display: flex;
  justify-content: center;
}

.anouncements {
  margin-top: 50px;
  background-color: #555;
  color: white;
  width: 200px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}